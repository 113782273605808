import 'date-fns';
import React from 'react';
import { Translate, withLocalize } from 'react-localize-redux';
import Alert from '@material-ui/lab/Alert';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import Select from '@material-ui/core/Select';
import Snackbar from '@material-ui/core/Snackbar';
import TextField from '@material-ui/core/TextField';
import CloseIcon from '@material-ui/icons/Close';
import { KeyboardDatePicker } from '@material-ui/pickers';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
const getOptionLabelC = (option) => option.name;
const getOptionSelectedC = (o, t) => o.id === t.id;

const useStyles = makeStyles({
  paper: {
    padding: '1rem',
  },
  button: {
    marginTop: '1rem',
  },
});

const getOptionLabel = (elements) => (option) => {
  if (typeof option === 'string') {
    let element = elements.find((c) => c.id === option);
    if (element === undefined) {
      return 'UNKNOWN';
    }
    return element.alias;
  }
  return option.alias;
};

const getOptionSelected = (o, t) => {
  if (typeof t === 'string') {
    return o.id === t;
  }
  return o.id === t.id;
};

const FinishedTransactionsFields = (props) =>
  props.report.type === 'finished-transactions' && (
    <>
      {
        /*!props.isSuperuser && (*/
        <FormControlLabel
          value='end'
          control={<Checkbox checked={props.report.all} name='all' onClick={props.onChange} color='primary' />}
          label={<Translate id='reports.allChargeboxes' />}
          labelPlacement='end'
        />
        /*)*/
      }
      {props.isSuperuser && props.report.all && (
        <Autocomplete
          openOnFocus
          autoHighlight
          options={props.clients}
          getOptionLabel={getOptionLabel(props.clients)}
          getOptionSelected={getOptionSelected}
          fullWidth
          value={props.report.client}
          onChange={(event, newValue) => props.onChange({ target: { name: 'client', value: newValue } })}
          renderInput={(params) => <TextField {...params} label={props.translate('chargeboxes.client')} />}
        />
      )}
      <Autocomplete
        options={props.chargeboxes}
        getOptionLabel={getOptionLabel(props.chargeboxes)}
        getOptionSelected={getOptionSelected}
        disabled={props.report.all}
        openOnFocus
        autoHighlight
        value={props.report.chargebox}
        onChange={(event, newValue) => props.onChange({ target: { name: 'chargebox', value: newValue } })}
        renderInput={(params) => <TextField {...params} label={props.translate('reports.chargebox')} />}
      />
    </>
  );

const ReservationsFields = (props) =>
  props.report.type === 'reservations' && (
    <>
      {
        /*!props.isSuperuser && (*/
        <FormControlLabel
          value='end'
          control={<Checkbox checked={props.report.all} name='all' onClick={props.onChange} color='primary' />}
          label={<Translate id='reports.allChargeboxes' />}
          labelPlacement='end'
        />
        /*)*/
      }
      {props.isSuperuser && props.report.all && (
        <Autocomplete
          openOnFocus
          autoHighlight
          options={props.clients}
          getOptionLabel={getOptionLabel(props.clients)}
          getOptionSelected={getOptionSelected}
          fullWidth
          value={props.report.client}
          onChange={(event, newValue) => props.onChange({ target: { name: 'client', value: newValue } })}
          renderInput={(params) => <TextField {...params} label={props.translate('chargeboxes.client')} />}
        />
      )}
      <Autocomplete
        options={props.chargeboxes}
        getOptionLabel={getOptionLabel(props.chargeboxes)}
        getOptionSelected={getOptionSelected}
        disabled={props.report.all}
        openOnFocus
        autoHighlight
        value={props.report.chargebox}
        onChange={(event, newValue) => props.onChange({ target: { name: 'chargebox', value: newValue } })}
        renderInput={(params) => <TextField {...params} label={props.translate('reports.chargebox')} />}
      />
    </>
  );

const RFIDRechargesFields = (props) =>
  props.report.type === 'rfids-recharges' && (
    <>
      {props.isSuperuser && (
        <Autocomplete
          openOnFocus
          autoHighlight
          options={props.clients}
          getOptionLabel={getOptionLabel(props.clients)}
          getOptionSelected={getOptionSelected}
          fullWidth
          value={props.report.client}
          onChange={(event, newValue) => props.onChange({ target: { name: 'client', value: newValue } })}
          renderInput={(params) => <TextField {...params} label={props.translate('chargeboxes.client')} />}
        />
      )}
      {props.isMain && (
        <Autocomplete
          openOnFocus
          autoHighlight
          options={props.clients}
          getOptionLabel={getOptionLabelC}
          getOptionSelected={getOptionSelectedC}
          fullWidth
          value={props.client}
          onChange={props.selectClient}
          disabled={props.processing}
          renderInput={(params) => <TextField {...params} label={<Translate id='home.filters.client' />} />}
        />
      )}
      <Grid container direction='row' spacing={2}>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth>
            <InputLabel id='reports-select-filter'>
              <Translate id='reports.filter' />
            </InputLabel>
            <Select labelId='reports-select-filter' value={props.report.filter} name='filter' onChange={props.onChange}>
              <MenuItem value='rfid'>
                <Translate id='reports.rfid' />
              </MenuItem>
              <MenuItem value='email'>
                <Translate id='reports.email' />
              </MenuItem>
              <MenuItem value='all'>
                <em>
                  <Translate id='reports.allRFIDs' />
                </em>
              </MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          {props.report.filter === 'rfid' && (
            <TextField fullWidth label={props.translate('reports.rfid')} name='rfid' value={props.report.rfid} onChange={props.onChange} />
          )}
          {props.report.filter === 'email' && (
            <TextField fullWidth label={props.translate('reports.email')} name='email' value={props.report.email} onChange={props.onChange} />
          )}
        </Grid>
      </Grid>
    </>
  );

const Reports = (props) => {
  const classes = useStyles(props);
  var date = new Date();
  date.setFullYear(date.getFullYear() - 2);
  return (
    <Grid container direction='row' alignItems='flex-start' justifyContent='flex-start'>
      <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={props.snackbar !== null} autoHideDuration={6000} onClose={props.onSnackbarClose}>
        <Alert
          elevation={6}
          variant='filled'
          severity='info'
          onClose={props.onSnackbarClose}
          icon={false}
          action={
            <IconButton size='small' aria-label='close' color='inherit' onClick={props.onSnackbarClose}>
              <CloseIcon fontSize='small' />
            </IconButton>
          }
        >
          {props.snackbar === null ? '' : <Translate id={`reports.snackbar.${props.snackbar}`} />}
        </Alert>
      </Snackbar>
      <Paper elevation={3} className={classes.paper}>
        <Grid container direction='column'>
          <FormControl>
            <InputLabel id='reports-select-type'>
              <Translate id='reports.type' />
            </InputLabel>
            <Select labelId='reports-select-type' value={props.report.type} name='type' onChange={props.onChange}>
              <MenuItem value='finished-transactions'>
                <Translate id='reports.types.finishedTransactions' />
              </MenuItem>
              <MenuItem value='reservations'>
                <Translate id='reports.types.reservations' />
              </MenuItem>
              <MenuItem value='rfids-recharges'>
                <Translate id='reports.types.rfidRecharges' />
              </MenuItem>
              {props.isSuperuser && (
                <MenuItem value='monthly-report'>
                  <Translate id='reports.types.monthlyReport' />
                </MenuItem>
              )}
            </Select>
          </FormControl>
          <Grid container direction='row' spacing={2}>
            <Grid item xs={12} sm={6}>
              <KeyboardDatePicker
                fullWidth
                variant='inline'
                format='dd/MM/yyyy'
                placeholder={props.translate('dateFormat')}
                margin='normal'
                label={<Translate id='reports.from' />}
                name='from'
                value={props.report.from}
                minDate={date}
                maxDate={new Date()}
                onChange={(d) => props.onChange({ target: { name: 'from', value: d } })}
                KeyboardButtonProps={{ 'aria-label': 'change date' }}
                autoOk
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <KeyboardDatePicker
                fullWidth
                variant='inline'
                format='dd/MM/yyyy'
                placeholder={props.translate('dateFormat')}
                margin='normal'
                label={<Translate id='reports.to' />}
                name='to'
                value={props.report.to}
                minDate={date}
                maxDate={new Date()}
                onChange={(d) => props.onChange({ target: { name: 'to', value: d } })}
                KeyboardButtonProps={{ 'aria-label': 'change date' }}
                autoOk
              />
            </Grid>
          </Grid>
          <FinishedTransactionsFields {...props} classes={classes} />
          <ReservationsFields {...props} classes={classes} />
          <RFIDRechargesFields {...props} classes={classes} />
          {props.isMain && props.report.all && props.report.type !== 'rfids-recharges' && (
            <Autocomplete
              openOnFocus
              autoHighlight
              options={props.clients}
              getOptionLabel={getOptionLabelC}
              getOptionSelected={getOptionSelectedC}
              fullWidth
              value={props.client}
              onChange={props.selectClient}
              disabled={props.processing}
              renderInput={(params) => <TextField {...params} label={<Translate id='home.filters.client' />} />}
              renderOption={(option) =>
                option.id === '*' ? (
                  <Typography noWrap>
                    <b>{option.name}</b>
                  </Typography>
                ) : (
                  <Typography noWrap>{option.name}</Typography>
                )
              }
              className={classes.autocomplete}
            />
          )}
          <Grid container direction='row' justifyContent='center' alignItems='center'>
            <Button variant='contained' color='primary' onClick={props.submit} className={classes.button}>
              <Translate id='reports.submit' />
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
};
export default withLocalize(Reports);
